<template>
    <div id="goals" style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            :title="isEdit ? 'Edição da campanha - '+newType : 'Nova campanha - '+newType"
            :haveBackButton="true"
            @onBack="backToModal"
            :haveContentMargin="true"
            >

            <template #filters>
                <b-row align-h="between">
                    <b-col>
                        <div class="form-group" style="opacity:0.5;">
                            <label for="">Categoria de investimento:</label>
                            <input disabled 
                            class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" 
                            v-model="newType">
                        </div>
                    </b-col>
                    <b-col>
                        <div class="form-group">
                            <label for="">Filtrar por:</label>
                            <select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" 
                            v-model="filterBy">
                                <option value="">Todas</option>
                                <option value="active">Ativo</option>
                                <option value="issuer">Emissor</option>
                                <option value="cnpj" v-if="newType!='Renda Fixa'">CNPJ</option>
                                <option value="roa" v-if="newType!='Renda Fixa'">ROA</option>
                            </select>
                        </div>
                    </b-col>
                    <b-col v-if="filterBy=='roa'">
                        <div class="form-group">
                            <label for="">Condição de ROA:</label>
                            <select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" 
                            v-model="roa_condition">
                                <option value="=">Igual a</option>
                                <option value=">">Maior que</option>
                                <option value="<">Menor que</option>
                            </select>
                        </div>
                    </b-col>
                    <b-col v-if="newType=='Renda Fixa'">
                        <InputDatePicker
                        :inputId="'inputDueDateStart'"
                        @input="(value)=>{ dueDateStart=value; getMinDate(dueDateStart) }"
                        label="Data de vencimento de:"
                        placeholder="Selecione"
                        :value="dueDateStart"
                        :initialDate="dueDateEnd"
                        :maxDate="maxDate"
                        resetButton="Cancelar"
                        />
                    </b-col>
                    <b-col v-if="newType=='Renda Fixa'">
                        <InputDatePicker
                        :inputId="'inputDueDateEnd'"
                        @input="(value)=>{ dueDateEnd=value; getMaxDate(dueDateEnd) }"
                        label="Data de vencimento até:"
                        placeholder="Selecione"
                        :value="dueDateEnd"
                        :initialDate="dueDateStart"
                        :minDate="minDate"
                        resetButton="Cancelar"
                        />
                    </b-col>
                    <b-col>
                        <div class="form-group">
                            <label for="">Pesquisar:</label>
                            <input type="text" class="form-control form-control-sm" id="inputUserName" placeholder="Digite aqui" 
                            style="box-shadow: none; height: 56px;" 
                            v-model="query">
                        </div>
                    </b-col>
                    <b-col cols="1" v-if="newType!='Renda Fixa' || filterBy!='roa'"></b-col>
                    <b-col cols="1" v-if="newType!='Renda Fixa'"></b-col>
                    <b-col class="d-flex justify-content-end align-items-center">
                        <CustomButton
                        @click="getItems"
                        > Pesquisar
                        </CustomButton>
                    </b-col>
                </b-row>
            </template>

            <!-- CONTENT -->
            <b-table v-if="renderTable" id="tableScroll" class="custom-table" borderless sticky-header :fields="newType=='Renda Fixa' ? rendaFixaColumns : columns" :items="items" show-empty>
                <template #empty="">
                    <b-col class="text-center pb-3">
                        Pesquise o ativo
                    </b-col>
                </template>


                <template #head(actions)="data">
                        <b-checkbox class="pl-4 ml-2" v-if="data" v-model="selectAll"
                        :disabled="selectAll"
                        @change="openSelectAll"
                        ></b-checkbox>
                </template>

                <template #cell(roa_funds)="row">
                    {{Number(row.item.roa_funds).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}}
                </template>

                <template #cell(actions)="row">
                    <b-button v-if="!isActive(row.item)" variant="secondary" class="p-0 px-1" @click="addActive(row.item)"
                    style="color: #eb694a; border-color:#eb694a; background-color:transparent !important; font-size:20px;">
                        <b-icon icon="plus"></b-icon>
                    </b-button>
                    <b-button v-else variant="secondary" class="p-0 px-1" disabled
                    style="color: #28A745; border-color:#28A745; background-color:transparent !important; font-size:20px;">
                        <b-icon icon="check"></b-icon>
                    </b-button>
                </template>


                <template #head()="data">
                    <b-row @click="data.field.sortable ? sort(data.column) : ()=>{}" align-v="center" class="m-0" :class="data.field.class" style="min-width:max-content;">
                        <span v-if="data.field.sortable" style="font-size:26px;" :style="sortBy==data.column ? 'color:white;' :'color:gray;'">
                            <b-icon :icon="sortDesc&&sortBy==data.column ? 'arrow-down-short' : 'arrow-up-short'"></b-icon>
                        </span>
                        <div v-else style="height: 38px;"></div>
                        {{ data.label }}
                    </b-row>
                </template>


            </b-table>

            <template #footer>
                <b-row class="m-0" align-h="between">
                    <CustomButton
                    @click="backToModal"
                    :disabled="true"
                    > Voltar
                    </CustomButton>


                    <b-row>
                        <CustomButton
                        variant="success"
                        :disabled="listActives.length==0"
                        not_clickable
                        bgColor="#28A745"
                        borderColor="#28A745"
                        textColor="#28A745"
                        @click="showActives"
                        > 
                            <b-icon icon="bag" class="mr-2"></b-icon>
                            Ativos incluídos
                        </CustomButton>

                        <div class="mx-3"></div>

                        <CustomButton
                        v-if="!isEdit"
                        @click="next"
                        :disabled="listActives.length==0"
                        > Próximo
                        </CustomButton>
                    </b-row>
                </b-row>
            </template>

            </DefaultPageComponent>

            <b-modal id="activesModal" title=" Ativos adicionados na campanha" centered hide-footer size="xl">
                <div>
                    <b-table :items="listActives" :fields="newType=='Renda Fixa' ? rendaFixaColumns : columns" sort-icon-left responsive sticky-header style="max-height:600px !important;">
                        <template #cell(actions)="row">
                            <b-button variant="secondary" class="px-2 py-1" @click="showRemoveActive(row.item)"
                            style="color: #eb694a; border-color:#eb694a; background-color:transparent !important; font-size:17px;">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </div>
            </b-modal>

        </b-col>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import InputDatePicker from '@/components/InputDatePicker.vue';
export default {
    name: "CampaignFormView",
    components:{
        DefaultPageComponent,
        CustomButton,
        InputDatePicker,
    },
    data(){
        return {
            loading: false,
            loadingSubmit: false,
            renderTable: true,

            isScrollLoading: false,
            page: 1,
            lastPage: 1,
            
            isEdit: false,
            detailId: null,
            detail: null,
            items: [],
            listActives: [],

            sortBy: null,
            sortDesc: true,
            
            minDate: null,
            maxDate: null,
            selectAll: false,
            dueDateStart: null,
            dueDateEnd: null,
            filterBy: '',
            roa_condition: '=',
            query: '',
            newType: '',
            rendaFixaColumns: [
                {
                    'product': 
                    {
                        label: 'Categoria',
                        class: 'text-left',
                        sortable: false,
                    }
                },
                {
                    'subproduct': 
                    {
                        label: 'Sub categoria',
                        class: 'text-left',
                        sortable: true,
                    }
                },
                {
                    'active': 
                    {
                        label: 'Ativo',
                        class: 'text-left',
                        sortable: true,
                    }
                },
                {
                    'issuer': 
                    {
                        label: 'Emissor',
                        class: 'text-left',
                        sortable: true,
                    }
                },
                {
                    'due_date': 
                    {
                        label: 'Data de vencimento',
                        class: 'text-right',
                        sortable: true,
                        formatter: (value) => {
                            return this.$options.filters.formatDate(value);
                        },
                    }
                },
                {
                    'actions': 
                    {
                        label: '',
                        class: 'text-center',
                        sortable: false,
                    }
                },
            ],
            columns: [
                {
                    'product': 
                    {
                        label: 'Categoria',
                        class: 'text-left',
                        sortable: false,
                    }
                },
                {
                    'subproduct': 
                    {
                        label: 'Sub categoria',
                        class: 'text-left',
                        sortable: true,
                    }
                },
                {
                    'n_document_fund': 
                    {
                        label: 'CNPJ',
                        class: 'text-left',
                        sortable: true,
                        formatter: (value) => {
                            return this.$options.filters.formatCnpj(value);
                        },
                    }
                },
                {
                    'active': 
                    {
                        label: 'Ativo',
                        class: 'text-left',
                        sortable: true,
                    }
                },
                {
                    'issuer': 
                    {
                        label: 'Emissor',
                        class: 'text-left',
                        sortable: true,
                    }
                },
                {
                    'roa_funds': 
                    {
                        label: 'ROA',
                        class: 'text-left',
                        sortable: true,
                    }
                },
                {
                    'actions': 
                    {
                        label: '',
                        class: 'text-center',
                        sortable: false,
                    }
                },
            ],
            intervalId:null,
        }
    },
    mounted(){
        if(!this.$route.params.type) this.$router.push({name:'campaigns'})
        this.newType = this.$route.params.type;
        
        if(this.$route.params.listActives){
            // this.getItems();
            this.listActives = this.$route.params.listActives
        }

        if(this.$route.params.isEdit)
            this.isEdit = this.$route.params.isEdit

        if(this.$route.params.detail)
            this.detail = this.$route.params.detail

        if(this.$route.params.detailId)
            this.detailId = this.$route.params.detailId

        this.intervalId = setInterval(()=>{
            this.onScroll();
        }, 1000)
    },
    beforeDestroy(){
        clearInterval(this.intervalId)
    },
    methods: {
        async forceRender(){
            this.renderTable = false;
            await this.$nextTick();
            this.renderTable = true;
        },
        isActive(item){
            return this.listActives.find(e=>e.active==item.active);
        },
        backToModal(){
            if(this.isEdit)
                this.$router.push({ name: "newCampaignTwo", params: {detailId: this.detailId, readonly: false, edit: true, detail: this.detail, type: this.newType, listActives: this.listActives} })
            else
                this.$router.push({ name:'campaigns', params: {showModal: true, type: this.newType} })
        },
        async getMinDate(date){
            if(!date) this.minDate=null;
            else this.minDate = new Date(date).toISOString().split('T')[0];
        },
        async getMaxDate(date){
            if(!date) this.maxDate=null;
            else this.maxDate = new Date(date).toISOString().split('T')[0];
        },
        async onScroll(){
            const element = document.querySelector('#tableScroll')?.parentElement;
            if (element.scrollTop + 2000 >= element.scrollHeight 
                && element.scrollHeight>500 
                && !this.loading && !this.isScrollLoading
                && this.page < this.lastPage
            ) {
                this.isScrollLoading=true;
                await this.getItems(this.page+1, true)
            }
        },
        async getItems(page=1, isScroll=false){
            try{
                if(isScroll) this.isScrollLoading=true;
                else this.loading=true;

                var classification_id = null;
                if(this.newType=='Renda Fixa') classification_id = 4;
                else if(this.newType=='Fundos') classification_id = 3;
                else if(this.newType=='Previdência') classification_id = 1;

                var endpoint = 'campaigns/eligible-actives?classification_id='+classification_id;

                if(this.dueDateStart || this.dueDateEnd){
                    var start = '';
                    if(this.dueDateStart) start = new Date(this.dueDateStart).toISOString().split('T')[0];
                    var end = '';
                    if(this.dueDateEnd) end = new Date(this.dueDateEnd).toISOString().split('T')[0];
                    endpoint+='&due_date='+(start&&end ? start+','+end : start||end);
                }

                if(this.filterBy!='roa' || this.filterBy=='roa' && !this.query) endpoint+="&roa=''"

                if(this.filterBy=='active') endpoint+='&active='+this.query;
                else if(this.filterBy=='issuer') endpoint+='&issuer='+this.query;
                else if(this.filterBy=='cnpj') endpoint+='&n_document_fund='+this.query;
                else if(this.filterBy=='roa' && this.query) endpoint+='&roa='+this.query.replace(/,/g, '.')+'&roa_condition='+this.roa_condition;
                else if(!this.filterBy && this.query){
                    endpoint+='&active='+this.query;
                    endpoint+='&issuer='+this.query;
                    endpoint+='&n_document_fund='+this.query;
                }

                endpoint+='&page='+page;
    
                const response = await this.$axios.get(endpoint);

                if(isScroll) this.items.push(...response.data.data);
                else this.items = response.data.data;
                
                this.page = response.data.current_page;
                this.lastPage = response.data.last_page;
            }
            catch(error){
                console.log(error);
            }
            finally{ 
                if(isScroll) this.isScrollLoading=false;
                else this.loading=false;
            }
        },
        openSelectAll(){
            if(this.items.length==0){
                this.$swal({
                    title: 'Atenção',
                    text: 'Pesquise o ativo antes de selecionar.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                this.selectAll=false;
                return;
            }

            var tempList = [...this.listActives];

            this.selectAllItems();

            this.$swal({
                title: !this.selectAll ? 'Remover todos os ativos' : 'Inserir todos os ativos',
                text: !this.selectAll ? 'Você selecionou todos os ativos da listagem para remoção da campanha. Deseja realmente remover todos esses ativos?' : `Você selecionou todos os ativos da listagem para inclusão na campanha. Deseja realmente incluir todos esses ativos?`,
                icon: 'warning',
                showDenyButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                denyButtonText: !this.selectAll ? 'Remover' : 'Incluir',
                denyButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    denyButton: 'custom-confirm-button-class',
                }
            }).then(async (result) => {
                if (!result.isConfirmed){
                    if(this.selectAll){
                        this.$swal({
                            title: 'Sucesso',
                            html: '<div style="display:flex; justify-content:center; align-items:center; flex-direction:column;"> O ativo foi inserido na campanha. <br/> Podendo ser verificado em <br/> <div style="margin-top:10px; border-radius:10px; background-color:#28A745; color: white; font-size: 18px; font-weight: 600; width:200px; height:56px; display:flex; justify-content:center; align-items:center;"> <svg data-v-05f04b1a="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="bag" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-bag mr-2 b-icon bi"><g data-v-05f04b1a=""><path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"></path></g></svg> Ativos incluídos </div></div>',
                            icon: 'success',
                            confirmButtonText: 'OK',
                            confirmButtonColor: '#28A745',
                            customClass: {
                                confirmButton: 'custom-confirm-button-class',
                            }
                        });
                    }
                    return;
                }
                else{
                    this.listActives = tempList;
                    this.selectAll=!this.selectAll;
                    this.forceRender();
                }
            });
        },
        selectAllItems(){
            if(this.selectAll)
                this.listActives = [...this.items]
            else if(!this.selectAll)
                this.listActives = []

            this.forceRender()
        },
        addActive(item){
            this.listActives.push(item);

            this.$swal({
                title: 'Sucesso',
                html: '<div style="display:flex; justify-content:center; align-items:center; flex-direction:column;"> O ativo foi inserido na campanha. <br/> Podendo ser verificado em <br/> <div style="margin-top:10px; border-radius:10px; background-color:#28A745; color: white; font-size: 18px; font-weight: 600; width:200px; height:56px; display:flex; justify-content:center; align-items:center;"> <svg data-v-05f04b1a="" viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="bag" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-bag mr-2 b-icon bi"><g data-v-05f04b1a=""><path d="M8 1a2.5 2.5 0 0 1 2.5 2.5V4h-5v-.5A2.5 2.5 0 0 1 8 1zm3.5 3v-.5a3.5 3.5 0 1 0-7 0V4H1v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4h-3.5zM2 5h12v9a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V5z"></path></g></svg> Ativos incluídos </div></div>',
                icon: 'success',
                confirmButtonText: 'OK',
                confirmButtonColor: '#28A745',
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                }
            });
        },
        showRemoveActive(item){
            this.$swal({
                title: 'Excluir o ativo da campanha?',
                text: `Deseja realmente excluir o ativo da listagem?`,
                icon: 'question',
                showDenyButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                denyButtonText: 'Excluir',
                denyButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    denyButton: 'custom-confirm-button-class',
                }
            }).then(async (result) => {
                if (!result.isConfirmed){
                    this.removeActive(item);
                }
            });
        },
        removeActive(item){
            var idx = this.listActives.findIndex(e=>e==item);
            this.listActives.splice(idx, 1);
            if(this.listActives.length==0)
                this.hideActives();

            this.selectAll=false;
            this.forceRender();
        },
        showActives(){
            if(this.listActives.length>0)
                this.$bvModal.show('activesModal')
            else{
                this.$swal({
                    title: 'Atenção',
                    text: 'Nenhum ativo incluído na campanha.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
        },
        hideActives(){
            this.$bvModal.hide('activesModal')
        },
        async next(){
            if(this.listActives.length>0){
                this.loading = true;
                this.$router.push({ name: "newCampaignTwo", params: {type: this.newType, listActives: this.listActives} })
            }
            else{
                this.$swal({
                    title: 'Atenção',
                    text: 'Nenhum ativo incluído na campanha.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
        },
        sort(key){
            if(this.sortBy==key)
                this.sortDesc = !this.sortDesc;
            else this.sortDesc = false;

            this.sortBy = key;

            this.items.sort((a,b)=>{
                if(this.sortDesc)
                    return String(b[key]).localeCompare(String(a[key]), undefined, {numeric: Boolean(Number(a[key])) })
                else return String(a[key]).localeCompare(String(b[key]), undefined, {numeric: Boolean(Number(a[key])) })
            })
        },
    },
}
</script>

<style scoped>
 * /deep/ th{
    transform:translateY(-1px);
    background: none !important;
    background-color: #535252 !important;
}
* /deep/ td{
    border:none !important;
    background-color: #2F2E2E !important;
}
/* * /deep/ table tbody tr td{
    padding: 5px 10px !important;
} */
* /deep/ .close{
    color: white !important;
}
* /deep/ .modal-header{
    border: none !important;
}
* /deep/ .modal-body{
    background-color: #2B2B2B !important;
}
* /deep/ .modal-xl{
    max-height: 1200px !important;
}
</style>
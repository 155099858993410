<template>
    <div id="goals" style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            :title="'Oportunidade de melhoria em fundos de previdência'"
            :haveBackButton="true"
            @onBack="$router.push({name: 'topPensionFunds'})"
            >

            <template #filters>
                <b-row class="m-0">

                    <b-col class="px-2">
                        <div class="form-group">
                            <label for="">Assessor:</label>
                            <b-select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;"
                            @change="getCustomers" :value="null" v-model="advisor" :disabled="loading">	
                                <option v-for="item,idx in advisors" :key="idx" :value="item.id"> {{ item.first_name + " " + item.last_name }} </option>
                            </b-select>
                        </div>
                    </b-col>
                    <b-col class="px-2">
                        <div class="form-group">
                            <label for="">Cliente:</label>
                            <b-form-select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;"
                            v-model="customer" :disabled="loading">	
                                <option :value="null"> {{ 'Todos' }} </option>
                                <option v-for="item,idx in customers" :key="idx" :value="item"> 
                                    {{ item }}
                                </option>
                            </b-form-select>
                        </div>
                    </b-col>
                    <div class="px-1"></div>
                    <CustomButton
                    @click="selectCustomer"
                    > Pesquisar
                    </CustomButton>
                    <b-col></b-col>
                </b-row>
            </template>

            <!-- CONTENT -->
            <div style="height: 100%" v-if="render">
                <b-row class="m-0 h-100">
                    <b-col cols="6" style="height:100%;">
                        <b-col class="py-3 my-3" style="max-width:max-content; height:calc(100% - 40px); background-color:#262625; border-radius:12px;">
                            <h5>Top Funds Previdência</h5>
                            <TopFundsOpportunitiesTable ref="tableOp" :canSelect="!customer" :items="modal ? modal : []" height="calc(100% - 40px)" width="min-content" :withOrder="true"
                            @select="(value)=>{
                                if(!customer) item=value;
                                loadOpportunities();
                            }"/>
                        </b-col>
                    </b-col>
                    <b-col cols="6" class="py-3" style="height:100%;">
                        <b-row class="m-0" align-h="center">
                            <b-col class="p-3" style="width:80%; max-width:fit-content; background-color:#262625; border-radius:12px;">
                                <h5>Top Funds Previdência</h5>
                                <TopFundsOpportunitiesTable :items="item ? [item] : []" height="calc(100% - 30px)" width="100%"/>
                            </b-col>
                        </b-row>

                        <div class="py-3"></div>

                        <b-row class="m-0" align-h="center" :style="'height:calc(100% - '+(item ? 235 : 145)+'px);'">
                            <b-col class="p-3" style="background-color:#262625; border-radius:12px; height:100%;">
                                <h5>Fundos da carteira</h5>
                                <b-table class="default-table" style="max-height:calc(100% - 40px) !important; overflow:auto; background-color:white !important"
                                :selectable="customer?true:false" sticky-header responsive 
                                :items="opportunities" 
                                select-mode="single"
                                sort-changed="onSortChanged"
                                :sort-by.sync="currentSort.key"
                                :sort-desc.sync="currentSort.desc"
                                @row-selected="(selected)=>{
                                    selectedRow = selected[0];
                                    item = {...modal?.find(e=>e.items?.find(i=>i.target.opportunities?.find(o=>o==selected[0])))}
                                    if(item) {
                                        item.items=[item.items.find(i=>i.target.opportunities.find(o=>o==selected[0]))];
                                        $refs.tableOp.modal=item.items[0];
                                    }
                                    else{
                                        $refs.tableOp.modal=null;
                                    }
                                }"
                                :tbody-tr-class="rowClass"
                                :fields="[
                                { 
                                    key: 'customer_code',
                                    label: 'Cliente',
                                    class: 'text-right',
                                    sortable: true,
                                },
                                { 
                                    key: 'customer_name',
                                    label: 'Nome',
                                    class: 'text-left',
                                    sortable: true,
                                },
                                { 
                                    key: 'n_document',
                                    label: 'CNPJ',
                                    class: 'text-left',
                                    sortable: true,
                                },
                                { 
                                    key: 'fund',
                                    label: 'Fundo',
                                    class: 'text-left',
                                    sortable: true,
                                },
                                { 
                                    key: 'roa',
                                    label: 'ROA',
                                    class: 'text-right',
                                    sortable: true,
                                },
                                { 
                                    key: 'absolute_return_12_months',
                                    label: 'Performance',
                                    class: 'text-right',
                                    sortable: true,
                                },
                                { 
                                    key: 'volatility',
                                    label: 'Vol.',
                                    class: 'text-right',
                                    sortable: true,
                                },
                                { 
                                    key: 'quantity',
                                    label: 'Quantidade',
                                    class: 'text-right',
                                    sortable: true,
                                },
                                { 
                                    key: 'net',
                                    label: 'NET',
                                    class: 'text-right',
                                    sortable: true,
                                },
                                ]">
                                    <template #head(customer_code)="data">
                                        <div class="d-flex align-items-center">
                                            <span style="font-size:16px;" :style="currentSort.key=='customer_code' ? 'color:white;' :'color:gray;'">
                                                <b-icon
                                                    v-if="currentSort.key === 'customer_code'"
                                                    :icon="currentSort.desc ? 'arrow-down' : 'arrow-up'"
                                                    class="mr-2"
                                                ></b-icon>
                                                <b-icon
                                                    v-else
                                                    :icon="'arrow-down'"
                                                    class="mr-2"
                                                ></b-icon>
                                            </span>
                                            {{data.label}}
                                        </div>
                                    </template>
                                    <template #head(customer_name)="data">
                                        <div class="d-flex align-items-center">
                                            <span style="font-size:16px;" :style="currentSort.key=='customer_name' ? 'color:white;' :'color:gray;'">
                                                <b-icon
                                                    v-if="currentSort.key === 'customer_name'"
                                                    :icon="currentSort.desc ? 'arrow-down' : 'arrow-up'"
                                                    class="mr-2"
                                                ></b-icon>
                                                <b-icon
                                                    v-else
                                                    :icon="'arrow-down'"
                                                    class="mr-2"
                                                ></b-icon>
                                            </span>
                                            {{data.label}}
                                        </div>
                                    </template>
                                    <template #head(n_document)="data">
                                        <div class="d-flex align-items-center">
                                            <span style="font-size:16px;" :style="currentSort.key=='n_document' ? 'color:white;' :'color:gray;'">
                                                <b-icon
                                                    v-if="currentSort.key === 'n_document'"
                                                    :icon="currentSort.desc ? 'arrow-down' : 'arrow-up'"
                                                    class="mr-2"
                                                ></b-icon>
                                                <b-icon
                                                    v-else
                                                    :icon="'arrow-down'"
                                                    class="mr-2"
                                                ></b-icon>
                                            </span>
                                            {{data.label}}
                                        </div>
                                    </template>
                                    <template #head(fund)="data">
                                        <div class="d-flex align-items-center">
                                            <span style="font-size:16px;" :style="currentSort.key=='fund' ? 'color:white;' :'color:gray;'">
                                                <b-icon
                                                    v-if="currentSort.key === 'fund'"
                                                    :icon="currentSort.desc ? 'arrow-down' : 'arrow-up'"
                                                    class="mr-2"
                                                ></b-icon>
                                                <b-icon
                                                    v-else
                                                    :icon="'arrow-down'"
                                                    class="mr-2"
                                                ></b-icon>
                                            </span>
                                            {{data.label}}
                                        </div>
                                    </template>
                                    <template #head(absolute_return_12_months)="data">
                                        <div class="d-flex align-items-center">
                                            <span style="font-size:16px;" :style="currentSort.key=='absolute_return_12_months' ? 'color:white;' :'color:gray;'">
                                                <b-icon
                                                    v-if="currentSort.key === 'absolute_return_12_months'"
                                                    :icon="currentSort.desc ? 'arrow-down' : 'arrow-up'"
                                                    class="mr-2"
                                                ></b-icon>
                                                <b-icon
                                                    v-else
                                                    :icon="'arrow-down'"
                                                    class="mr-2"
                                                ></b-icon>
                                            </span>
                                            {{data.label}}
                                        </div>
                                    </template>
                                    <template #head(volatility)="data">
                                        <div class="d-flex align-items-center">
                                            <span style="font-size:16px;" :style="currentSort.key=='volatility' ? 'color:white;' :'color:gray;'">
                                                <b-icon
                                                    v-if="currentSort.key === 'volatility'"
                                                    :icon="currentSort.desc ? 'arrow-down' : 'arrow-up'"
                                                    class="mr-2"
                                                ></b-icon>
                                                <b-icon
                                                    v-else
                                                    :icon="'arrow-down'"
                                                    class="mr-2"
                                                ></b-icon>
                                            </span>
                                            {{data.label}}
                                        </div>
                                    </template>
                                    <template #head(quantity)="data">
                                        <div class="d-flex align-items-center">
                                            <span style="font-size:16px;" :style="currentSort.key=='quantity' ? 'color:white;' :'color:gray;'">
                                                <b-icon
                                                    v-if="currentSort.key === 'quantity'"
                                                    :icon="currentSort.desc ? 'arrow-down' : 'arrow-up'"
                                                    class="mr-2"
                                                ></b-icon>
                                                <b-icon
                                                    v-else
                                                    :icon="'arrow-down'"
                                                    class="mr-2"
                                                ></b-icon>
                                            </span>
                                            {{data.label}}
                                        </div>
                                    </template>
                                    <template #head(net)="data">
                                        <div class="d-flex align-items-center">
                                            <span style="font-size:16px;" :style="currentSort.key=='net' ? 'color:white;' :'color:gray;'">
                                                <b-icon
                                                    v-if="currentSort.key === 'net'"
                                                    :icon="currentSort.desc ? 'arrow-down' : 'arrow-up'"
                                                    class="mr-2"
                                                ></b-icon>
                                                <b-icon
                                                    v-else
                                                    :icon="'arrow-down'"
                                                    class="mr-2"
                                                ></b-icon>
                                            </span>
                                            {{data.label}}
                                        </div>
                                    </template>
                                    <template #cell="{ rowSelected }">
                                        <template v-if="rowSelected">
                                            <div style="opacity:1"></div>
                                        </template>
                                        <template v-else>
                                            <div style="opacity:0.7"></div>
                                        </template>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>

            <template #footer>
                <div></div>
            </template>

            </DefaultPageComponent>
        </b-col>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import { mapState } from 'vuex';
import CustomButton from '@/components/CustomButton.vue';
import TopFundsOpportunitiesTable from '@/components/TopFundsOpportunitiesTable.vue';

export default {
    name: "TopPensionFundsOpportunitiesView",
    components:{
        DefaultPageComponent,
        TopFundsOpportunitiesTable,
        CustomButton,
    },
    data(){
        return {
            render: true,
            loading: false,
            loadingSubmit: false,

            query: null,

            modal: [],
            items: [],
            opportunities: [],
            item: null,
            selectedRow:null,

            advisor: null,
            customer: null,

            advisors: [],
            customers: [],

            currentSort: {
                key: "customer_code",
                desc: false,
            },

            changedAdvisor: false,
        }
    },
    async created(){
        this.loading=true;
        await this.getAdvisors();
        this.getItems();
    },
    computed: {
        ...mapState('user', {
            user: (state) => state.user,
        }),
    },
    methods: {
        onSortChanged({ sortBy, sortDesc }) {
            this.currentSort.key = sortBy;
            this.currentSort.desc = sortDesc;
        },
        rowClass(item) {
            if (!item) return "row-selected";
            return this.selectedRow == item || !this.item || !this.customer || !this.selectedRow
                ? "row-selected"
                : "row-unselected";
        },
        async forceRender(){
            this.render = false;
            await this.$nextTick();
            this.render=true;
        },
        clear(){
            this.item = null;
            this.modal = null;
            this.selectedRow = null;
            this.forceRender();
        },
        getUserAdvisor(){ 
            this.advisor = this.advisors.find(e=>e.id==this.user.advisor_id).id
            this.getCustomers();
        },
        async getCustomers(){
            // try{
            //     const response = await this.$axios.get('advisors/customers-details?advisor_id='+this.advisor)
            //     this.customers = response.data[0]?.customers;
            // }
            // catch(error){
            //     console.log(error);
            // }
        },
        async getItems(){
            try{
                this.loading=true;
                var endpoint = 'top-pension-funds-opportunity';
                endpoint+='?advisor_id='+this.advisor;
                if(this.customer) endpoint+='&customer_id='+this.customer.split(' - ')[0];
                const response = await this.$axios.get(endpoint);
                if (this.changedAdvisor)
                    this.customers = [];
                this.changedAdvisor = false;
                this.modal = response.data.filter(obj => {
                    const temp = obj.items.filter(e=>e.target.opportunities.length>0)
                    obj.items = temp;
                    return temp.some(item => item.target.opportunities.length>0)
                });
                this.modal.sort((a,b)=>a.position-b.position)
                this.modal.forEach(item => {
                    item.items.forEach(item => {
                        item.target.opportunities.forEach(item => {
                            if (!this.customers.includes(item.customer_code + " - " + item.customer_name)) {
                                this.customers.push(item.customer_code + " - " + item.customer_name);
                            }
                        })
                    })
                })
            }
            catch(error){
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar listagem de Top Funds Previdência Oportunidades.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        async getAdvisors () {
            try{
                const user = {...this.user};
                const response = await this.$axios.get('advisors')
                this.advisors = response.data.data;
    
                if (user.hunter_ids.length > 0)
                    this.advisors = this.advisors.filter(advisor => {
                        return user.hunter_ids.includes(advisor.id)
                    });
                this.getUserAdvisor();
            }
            catch(error){
                console.log(error);
            }
        },
        async selectCustomer(){
            this.loading=true;
            this.clear();
            await this.getItems();
            this.loadOpportunities();
        },
        loadOpportunities(){
            if(this.customer) this.opportunities = this.modal?.flatMap(group=>group.items.flatMap(item=>item.target?.opportunities)) 
            else this.opportunities = this.item?.items[0]?.target?.opportunities
        },
        showSenders(item){
            item;
        },
        showDetails(item){
            item;
        },
    },
    watch: {
        'advisor': function () {
            this.customer = null;
            this.changedAdvisor = true;
            this.opportunities = [];
            this.item = [];
            this.getItems();
        }
    }
}
</script>

<style scoped>
* /deep/ th {
    border: none !important;
}
* /deep/ .row-selected {
  opacity: 1 !important;
}
* /deep/ .row-unselected {
  opacity: 0.7 !important;
}
* /deep/ tbody tr {
  transition: 0.25s !important;
}
</style>
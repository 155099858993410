<template>
    <div id="goals" style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            :title="'Gráfico de comparação de carteiras'"
            :haveBackButton="true"
            @onBack="$router.push({name: 'dashboard'})"
            :haveContentMargin="true"
            >

            <template #filters>
                <b-row class="mx-0">
                    <b-col class="px-2">
                        <div class="form-group">
                            <label for="">Assessor:</label>
                            <b-select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;"
                            @change="getCustomers" :value="null" v-model="advisor" :disabled="loading">	
                                <option v-for="item,idx in advisors" :key="idx" :value="item.id"> {{ item.first_name + " " + item.last_name }} </option>
                            </b-select>
                        </div>
                    </b-col>
                    <b-col class="px-2">
                        <div class="form-group">
                            <label for="">Cliente:</label>
                            <b-form-select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;"
                            v-model="customer" :disabled="loading">	
                                <option :value="null" disabled> {{ 'Selecione' }} </option>
                                <option v-for="item,idx in customers" :key="idx" :value="item"> 
                                    {{ item.xp_code+" - "+item.customer_full_name }}
                                </option>
                            </b-form-select>
                        </div>
                    </b-col>
                    <b-col class="px-2">
                        <CustomButton
                        :loading="loading"
                        :disabled="isDisabled"
                        @click="getItems"
                        :width="'260px'"
                        > Pesquisar
                        </CustomButton>
                    </b-col>
                    <b-col class="px-1"></b-col>
                    <b-col class="px-1"></b-col>
                </b-row>
            </template>

            <!-- CONTENT -->
            <div style="position:relative; min-height: 100%; width: 100%;">
                <!-- <b-tabs @input="getItems" v-model="tabIndex"> -->
                <b-tabs v-model="tabIndex">
                    <b-tab title="Top Funds">
                        <div class="p-3">
                            <b-row no-gutters align-h="end">
                                <CustomButton
                                @click="()=>{}"
                                :width="'260px'"
                                > 
                                    <b-icon class="mr-2" icon="arrow-up-right-square-fill"></b-icon>
                                    Visualizar em nova aba
                                </CustomButton>
                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab title="Fundos">
                        <div class="p-3">
                            <b-row no-gutters align-h="end">
                                <CustomButton
                                @click="()=>{}"
                                :width="'260px'"
                                > 
                                    <b-icon class="mr-2" icon="arrow-up-right-square-fill"></b-icon>
                                    Visualizar em nova aba
                                </CustomButton>
                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab title="Top Funds Previdência">
                        <div class="p-3">
                            <b-row no-gutters align-h="end">
                                <CustomButton
                                @click="()=>{}"
                                :width="'260px'"
                                > 
                                    <b-icon class="mr-2" icon="arrow-up-right-square-fill"></b-icon>
                                    Visualizar em nova aba
                                </CustomButton>
                            </b-row>
                        </div>
                    </b-tab>
                    <b-tab title="Previdência">
                        <div class="p-3">
                            <b-row no-gutters align-h="end">
                                <CustomButton
                                @click="()=>{}"
                                :width="'260px'"
                                > 
                                    <b-icon class="mr-2" icon="arrow-up-right-square-fill"></b-icon>
                                    Visualizar em nova aba
                                </CustomButton>
                            </b-row>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>

            <template #footer>
                <div></div>
            </template>

            </DefaultPageComponent>

        </b-col>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import { mapState } from 'vuex';

export default {
    name: "PortfolioComparisonView",
    components:{
        CustomButton,
        DefaultPageComponent,
    },
    data(){
        return {
            loading: false,
            loadingSubmit: false,

            tabIndex: 0,

            advisors: [],
            customers: [],
            advisor: null,
            customer: null,

            items: [],
            columns: [
                {
                    'ADVISOR': 
                    {
                        label: 'Assessor',
                        class: 'text-left',
                    }
                },
            ],
        }
    },
    async created(){
        await this.getAdvisors();
        await this.getItems();
    },
    computed:{
        ...mapState('user', {
            user: (state) => state.user,
        }),
        isDisabled(){
            return (
                this.loading 
                || this.loadingSubmit 
                || (
                    this.tabIndex==1 || this.tabIndex==3 && (
                        !this.advisor 
                        || !this.customer
                    )
                   )
            );
        },
    },
    methods: {
        async getItems(){
            if(this.isDisabled){
                this.$swal({
                    title: 'Atenção',
                    text: 'Selecione um assessor e um cliente.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return false;
            }
            try{
                this.loading=true;
                var endpoint = null;

                if(this.tabIndex==0) endpoint = 'top-funds/chart-data';
                else if(this.tabIndex==1) endpoint = 'customers/portfolio';
                else if(this.tabIndex==2) endpoint = 'top-pension-funds/chart-data';
                else if(this.tabIndex==3) endpoint = 'customers/portfolio';

                if(this.tabIndex==1 || this.tabIndex==3){
                    if(this.advisor) endpoint+='?advisor_id='+this.advisor;
                    if(this.customer) endpoint+='&customer_id='+this.customer;

                    if(this.tabIndex==1) endpoint+='&classification_id=3';
                    else if(this.tabIndex==3) endpoint+='&classification_id=1';
                }
    
                const response = await this.$axios.get(endpoint);
                this.items = response.data;
            }
            catch(error){
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        // async getAdvisors(){
        //     const response = await this.$axios.get('advisors');
        //     this.advisors = response.data.data.filter(e=>e.advisorCommercialPartner[0]?.code);
        // },
        async getAdvisors () {
            try{
                const user = {...this.user};
                const response = await this.$axios.get('advisors')
                this.advisors = response.data.data;
    
                if (user.hunter_ids.length > 0)
                    this.advisors = this.advisors.filter(advisor => {
                        return user.hunter_ids.includes(advisor.id)
                    });
                this.getUserAdvisor();
            }
            catch(error){
                console.log(error);
            }
        },
        getUserAdvisor(){ 
            this.advisor = this.advisors.find(e=>e.id==this.user.advisor_id).id
            this.getCustomers();
        },
        async getCustomers(){
            try{
                const response = await this.$axios.get('advisors/customers-details?advisor_id='+this.advisor)
                this.customers = response.data[0]?.customers;
            }
            catch(error){
                console.log(error);
            }
        },
        async save(){
            // if(!this.file) {
            //     this.$swal({
            //         title: 'Atenção',
            //         text: 'A importação do arquivo é obrigatória.',
            //         icon: 'warning',
            //         confirmButtonText: 'OK',
            //         confirmButtonColor: '#28A745',
            //         allowOutsideClick: false,
            //         customClass: {
            //             confirmButton: 'custom-confirm-button-class',
            //         }
            //     });
            //     return false;
            // }
            // try{
            //     this.loading = true;
            //     this.loadingSubmit = true;

            //     const post = await this.saveGoalsFileImport();
                
            //     await Promise.all([post]);
                
            //     this.$swal({
            //         title: 'Sucesso',
            //         text: 'Arquivo importado com sucesso.',
            //         icon: 'success',
            //         confirmButtonText: 'OK',
            //         confirmButtonColor: '#28A745',
            //         allowOutsideClick: false,
            //         focusConfirm: false,
            //         customClass: {
            //             confirmButton: 'custom-confirm-button-class',
            //         }
            //     });
    
            //     this.clear();
            //     this.$refs.modalNewGoal.closeModal();
            // }
            // catch(error){
            //     console.log(error);
            //     this.$swal({
            //         title: 'Atenção',
            //         text: 'Houve um erro com a importação do arquivo!',
            //         icon: 'error',
            //         confirmButtonText: 'OK',
            //         confirmButtonColor: '#28A745',
            //         allowOutsideClick: false,
            //         customClass: {
            //             confirmButton: 'custom-confirm-button-class',
            //         }
            //     });
            // }
            // finally {
            //     this.loading = false;
            //     this.loadingSubmit = false;
            // }
        },
    },
}
</script>

<style scoped>
/* * /deep/ .modal-card{
    max-width:fit-content !important;
} */

* /deep/ .nav-link{
    width: 220px !important;
    text-align: center !important;
    color: #FFF !important;
    background-color: #5C5C58 !important;
    border: 2px solid #1C1B1B !important;
    border-radius: 0px !important;
    font-weight: 600;
}
* /deep/ .active{
    color: #FFF !important;
    background: #262625 !important;
    border: 2px solid #1C1B1B !important;
}
* /deep/ .tab-content{
    background-color: #262625 !important;
}
* /deep/ .nav-tabs {
    border-bottom: 2px solid #1C1B1B !important;
}
</style>
import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthLayout from '@/layouts/AuthLayout.vue'
import LoginView from '@/views/LoginView.vue'
import RecoverView from '@/views/RecoverView.vue'
import NewPasswordView from '@/views/NewPasswordView.vue'
import Guard from '@/service/middleware'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import DashboardView from '@/views/DashboardView.vue'
import PendingRulesView from '@/views/PendingRulesView.vue'
import CampaignsView from '@/views/CampaignsView.vue'
import CampaignFormView from '@/views/CampaignFormView.vue'
import CampaignFormTwoView from '@/views/CampaignFormTwoView.vue'
import CampaignCustomersView from '@/views/CampaignCustomersView.vue'
import ProfileView from '@/views/ProfileView.vue'
import CommercialPartnerView from '@/views/CommercialPartnerView.vue'
import CommercialPartnerFormView from '@/views/CommercialPartnerFormView.vue'
import CommercialPartnerEditView from '@/views/CommercialPartnerEditView.vue'
import UserView from '@/views/UserView.vue'
import UserFormView from '@/views/UserFormView.vue'
import UserEditView from '@/views/UserEditView.vue'
import CustomerView from '@/views/CustomerView.vue'
import CustomerAddView from '@/views/CustomerAddView.vue'
import CustomerEditView from '@/views/CustomerEditView.vue'
import AdvisorView from '@/views/AdvisorView.vue'
import AdvisorFormView from '@/views/AdvisorFormView.vue'
import AdvisorEditView from '@/views/AdvisorEditView.vue'
import CompanyView from '@/views/CompanyView.vue'
import CompanyFormView from '@/views/CompanyFormView.vue'
import CompanyEditView from '@/views/CompanyEditView.vue'
import CellView from '@/views/CellView.vue'
import CellFormView from '@/views/CellFormView.vue'
import CellEditView from '@/views/CellEditView.vue'
import BankView from '@/views/BankView.vue'
import PermissionsView from '@/views/PermissionsView.vue'
import FGCView from '@/views/FGCView.vue'
import ReportRFView from '@/views/ReportRFView.vue'
import ImportEstratificationView from '@/views/ImportEstratificationView.vue'
import ObjectivesView from '@/views/ObjectivesView.vue'
import ImportDiversificationDaily from '@/views/ImportDiversificationDaily.vue'
import ImportCockpitView from '@/views/ImportCockpitView.vue'
import ImportCustomerView from '@/views/ImportCustomerView.vue'
import TagsView from '@/views/TagsView.vue'
import TagAddView from '@/views/TagAddView.vue'
import TagEditView from '@/views/TagEditView.vue'
import AttachmentsView from '@/views/AttachmentsView.vue'
import AttachmentAddView from '@/views/AttachmentAddView.vue'
import AttachmentEditView from '@/views/AttachmentEditView.vue'
import DefaultMessagesView from '@/views/DefaultMessagesView.vue'
import DefaultMessagesAddView from '@/views/DefaultMessagesAddView.vue'
import DefaultMessageEditView from '@/views/DefaultMessageEditView.vue'
import IntegrationView from '@/views/IntegrationView.vue'
import MessagesView from '@/views/MessageView.vue'
import MessagesAddView from '@/views/MessageAddView.vue'
import MessagesEditView from '@/views/MessageEditView.vue'
import EmailsView from '@/views/EmailsView.vue'
import EmailsNewView from '@/views/EmailsNewView.vue'
import EmailsSenderView from '@/views/EmailsSenderView.vue'
import EmailsSenderSeeView from '@/views/EmailsSenderSeeView.vue'
import EmailConfigurationView from '@/views/EmailConfigurationView.vue'
import TopFundsView from '@/views/TopFundsView.vue'
import TopFundsFormView from '@/views/TopFundsFormView.vue'
import TopFundsOpportunitiesView from '@/views/TopFundsOpportunitiesView.vue'
import TopPensionFundsView from '@/views/TopPensionFundsView.vue'
import TopPensionFundsFormView from '@/views/TopPensionFundsFormView.vue'
import TopPensionFundsOpportunitiesView from '@/views/TopPensionFundsOpportunitiesView.vue'
import PortfolioComparisonView from '@/views/PortfolioComparisonView.vue'
import DefaultEmailsView from '@/views/DefaultEmailsView.vue'
import DefaultEmailsNewView from '@/views/DefaultEmailsNewView.vue'
import StratificationView from '@/views/StratificationView.vue'
import CockpitView from '@/views/CockpitView.vue'
import GoalsView from '@/views/GoalsView.vue'
import LogView from '@/views/LogView.vue'
import EmailClassificationView from '@/views/EmailClassificationView.vue'
import EmailClassificationAddView from '@/views/EmailClassificationAddView.vue'
import EmailClassificationEditView from '@/views/EmailClassificationEditView.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/auth',
        component: AuthLayout,
        beforeEnter: Guard.redirectIfAuthenticated,
        children: [
            {
                path: '/login',
                name: 'login',
                component: LoginView,
            },
            {
                path: '/recover',
                name: 'recoverPassword',
                component: RecoverView,
            },
            {
                path: '/new-password/:token',
                name: 'newPassword',
                component: NewPasswordView,
            },
        ],
    },
    {
        path: '/',
        component: DefaultLayout,
        beforeEnter: Guard.redirectIfNotAuthenticated,
        children: [
            {
                path: '',
                name: 'dashboard',
                component: DashboardView,
            },
            {
                path: '/stratification',
                name: 'stratification',
                component: StratificationView,
            },
            {
                path: '/pending-rules',
                name: 'pendingRules',
                component: PendingRulesView,
            },
            {
                path: '/campaigns',
                name: 'campaigns',
                component: CampaignsView,
            },
            {
                path: '/campaigns/new/',
                name: 'newCampaign',
                component: CampaignFormView,
            },
            {
                path: '/campaigns/create/',
                name: 'newCampaignTwo',
                component: CampaignFormTwoView,
            },
            {
                path: '/campaigns/customers/',
                name: 'campaignCustomers',
                component: CampaignCustomersView,
            },
            {
                path: '/profile',
                name: 'profile',
                component: ProfileView,
            },
            {
                path: '/customers',
                name: 'customer',
                component: CustomerView,
            },
            {
                path: '/customers/add',
                name: 'customerAdd',
                component: CustomerAddView,
            },
            {
                path: '/customers/edit/:id',
                name: 'customerEdit',
                component: CustomerEditView,
            },
            {
                path: '/advisors',
                name: 'advisor',
                component: AdvisorView,
            },
            {
                path: '/advisors/new',
                name: 'advisorNew',
                component: AdvisorFormView,
            },
            {
                path: '/advisors/edit/:id',
                name: 'advisorEdit',
                component: AdvisorEditView,
            },
            {
                path: '/commercial-partner',
                name: 'commercialPartner',
                component: CommercialPartnerView,
            },
            {
                path: '/commercial-partner/new',
                name: 'commercialPartnerNew',
                component: CommercialPartnerFormView,
            },
            {
                path: '/commercial-partner/edit/:id',
                name: 'commercialPartnerEdit',
                component: CommercialPartnerEditView,
            },
            {
                path: '/users',
                name: 'users',
                component: UserView,
            },
            {
                path: '/users/new',
                name: 'usersNew',
                component: UserFormView,
            },
            {
                path: '/users/edit/:id',
                name: 'usersEdit',
                component: UserEditView,
            },
            {
                path: '/companies',
                name: 'companies',
                component: CompanyView,
            },
            {
                path: '/companies/new',
                name: 'companiesNew',
                component: CompanyFormView,
            },
            {
                path: '/companies/edit/:id',
                name: 'companiesEdit',
                component: CompanyEditView,
            },
            {
                path: '/cells',
                name: 'cells',
                component: CellView,
            },
            {
                path: '/cells/new',
                name: 'cellsNew',
                component: CellFormView,
            },
            {
                path: '/cells/edit/:id',
                name: 'cellsEdit',
                component: CellEditView,
            },
            {
                path: '/objectives',
                name: 'objectives',
                component: ObjectivesView,
            },
            {
                path: '/banks',
                name: 'banks',
                component: BankView,
            },
            {
                path: '/permissions',
                name: 'permissions',
                component: PermissionsView,
            },
            {
                path: '/fgc',
                name: 'fgc',
                component: FGCView,
            },
            {
                path: '/report-rf-maturities',
                name: 'reportRfMaturities',
                component: ReportRFView,
            },
            {
                path: '/import-estratification',
                name: 'importEstratification',
                component: ImportEstratificationView,
            },
            {
                path: '/import-diversification-daily',
                name: 'importDiversificationDaily',
                component: ImportDiversificationDaily,
            },
            {
                path: '/import-cockpit',
                name: 'importCockpit',
                component: ImportCockpitView,
            },
            {
                path: '/import-customer',
                name: 'importCustomer',
                component: ImportCustomerView,
            },
            {
                path: '/tags',
                name: 'tags',
                component: TagsView,
            },
            {
                path: '/tags/add',
                name: 'tagAdd',
                component: TagAddView,
            },
            {
                path: '/tags/edit/:id',
                name: 'tagEdit',
                component: TagEditView,
            },
            {
                path: '/attachments',
                name: 'attachments',
                component: AttachmentsView,
            },
            {
                path: '/attachments/add',
                name: 'attachmentAdd',
                component: AttachmentAddView,
            },
            {
                path: '/attachments/edit/:id',
                name: 'attachmentEdit',
                component: AttachmentEditView,
            },
            {
                path: '/default-messages',
                name: 'defaultMessages',
                component: DefaultMessagesView,
            },
            {
                path: '/default-messages/add',
                name: 'defaultMessageAdd',
                component: DefaultMessagesAddView,
            },
            {
                path: '/default-messages/edit/:id',
                name: 'defaultMessageEdit',
                component: DefaultMessageEditView,
            },
            {
                path: '/integrations',
                name: 'IntegrationView',
                component: IntegrationView,
            },
            {
                path: '/emails',
                name: 'emailsView',
                component: EmailsView,
            },
            {
                path: '/emails/form/:id?',
                name: 'emailsViewForm',
                component: EmailsNewView,
            },
            {
                path: '/emails/senders',
                name: 'emailsSendersView',
                component: EmailsSenderView,
            },
            {
                path: '/emails/senders/see',
                name: 'emailsSendersSeeView',
                component: EmailsSenderSeeView,
            },
            {
                path: '/top-funds',
                name: 'topFunds',
                component: TopFundsView,
            },
            {
                path: '/top-funds/form',
                name: 'topFundsForm',
                component: TopFundsFormView,
            },
            {
                path: '/top-funds/opportunities',
                name: 'topFundsOpportunities',
                component: TopFundsOpportunitiesView,
            },
            {
                path: '/top-pension-funds',
                name: 'topPensionFunds',
                component: TopPensionFundsView,
            },
            {
                path: '/top-pension-funds/form',
                name: 'topPensionFundsForm',
                component: TopPensionFundsFormView,
            },
            {
                path: '/top-pension-funds/opportunities',
                name: 'topPensionFundsOpportunities',
                component: TopPensionFundsOpportunitiesView,
            },
            {
                path: '/portfolio-comparison',
                name: 'portfolioComparison',
                component: PortfolioComparisonView,
            },
            {
                path: '/default-emails',
                name: 'defaultEmailsView',
                component: DefaultEmailsView,
            },
            {
                path: '/default-emails/form/',
                name: 'defaultEmailsViewForm',
                component: DefaultEmailsNewView,
            },
            {
                path: '/email-classification',
                name: 'emailClassificationView',
                component: EmailClassificationView,
            },
            {
                path: '/email-classification/add',
                name: 'emailClassificationAdd',
                component: EmailClassificationAddView,
            },
            {
                path: '/email-classification/edit/:id',
                name: 'emailClassificationEdit',
                component: EmailClassificationEditView,
            },
            {
                path: '/email-configuration',
                name: 'emailConfigurationView',
                component: EmailConfigurationView,
            },
            {
                path: '/messages',
                name: 'messagesView',
                component: MessagesView,
            },
            {
                path: '/messages/add',
                name: 'messageAdd',
                component: MessagesAddView,
            },
            {
                path: '/messages/edit/:id',
                name: 'messageEdit',
                component: MessagesEditView,
            },
            {
                path: '/cockpit',
                name: 'cockpit',
                component: CockpitView,
            },
            {
                path: '/goals',
                name: 'goals',
                component: GoalsView,
            },
            {
                path: '/logs',
                name: 'logs',
                component: LogView,
            },
        ],
    },
    {
        path: '*',
        redirect: '/',
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    if (
        (to.matched.length>0 && to.matched[0].path=='/auth')
        // || to.name === 'login'
    ) {
        Guard.redirectIfAuthenticated(to, from, next)
    } else {
        Guard.redirectIfNotAuthenticated(to, from, next)
    }
});

export default router

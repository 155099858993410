<template>
    <div id="goals" style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            :title="isEdit ? 'Edição da campanha' : readonly ? 'Campanha - ' + title : 'Nova campanha'"
            haveBackButton
            @onBack="back"
            haveContentMargin
            >

            <!-- CONTENT -->
            <b-col class="h-100" style="max-height: calc(100vh - 450px) !important;">
                <b-row class="mb-3 pt-3" align-h="between">
                    <b-col>
                        <div class="form-group" :style="readonly? '' : 'opacity:0.5;'">
                            <label for="">Categoria da campanha:</label>
                            <input disabled 
                            class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" 
                            v-model="newType">
                        </div>
                    </b-col>
                    <b-col>
                        <InputDatePicker
                        :inputId="'inputStart'"
                        @input="(value)=>{ dateStart=value; getMinDate(dateStart) }"
                        label="Data de início:"
                        placeholder="Selecione"
                        :value="dateStart"
                        :initialDate="dateEnd"
                        :maxDate="maxDate"
                        resetButton="Cancelar"
                        :disabled="readonly"
                        />
                    </b-col>
                    <b-col>
                        <InputDatePicker
                        :inputId="'inputEnd'"
                        @input="(value)=>{ dateEnd=value; getMaxDate(dateEnd) }"
                        label="Data de término:"
                        placeholder="Selecione"
                        :value="dateEnd"
                        :initialDate="dateStart"
                        :minDate="minDate"
                        resetButton="Cancelar"
                        :disabled="readonly"
                        />
                    </b-col>
                    <b-col></b-col>
                </b-row>

                <b-row v-if="!readonly" class="my-4 py-2" align-h="between">
                    <b-col>
                        <div class="form-group">
                            <label for="">Título da campanha:</label>
                            <input placeholder="Informe um título para campanha" :disabled="readonly"
                            class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;" 
                            v-model="title">
                        </div>
                    </b-col>
                </b-row>

                <b-row class="h-50" :class="readonly ? 'my-4 py-2' : 'my-3 pb-2'" align-h="between">
                    <b-col class="h-100">
                        <div class="form-group h-100">
                            <label for="">Descrição da campanha:</label>
                            <b-form-textarea placeholder="Informe uma descrição para campanha"  no-resize
                            class="form-control form-control-sm py-2 h-100" id="inputPesquisarPor" style="box-shadow: none;" 
                            :disabled="readonly" v-model="description"></b-form-textarea>
                        </div>
                    </b-col>
                </b-row>

                <b-row class="mx-0 mt-3 mb-1">
                    <span style="font-size:18px; font-weight:600;"> Ativos </span>
                </b-row>
                <b-table class="custom-table" style="max-height:50% !important" borderless sticky-header :fields="newType=='Renda Fixa' ? rendaFixaColumns : columns" :items="listActives" show-empty>
                    <template #empty="">
                        <b-col class="text-center pb-3">
                            Nenhum ativo incluído na campanha
                        </b-col>
                    </template>
                    <template #cell(actions)="row">
                        <b-button v-if="!readonly" variant="secondary" class="px-2 py-1" @click="showRemoveActive(row.item)"
                            style="color: #eb694a; border-color:#eb694a; background-color:transparent !important; font-size:17px;">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                    </template>
                </b-table>
            </b-col>
            

            <template #footer>
                <b-row class="m-0" align-h="between">
                    <CustomButton
                    @click="back"
                    :disabled="true"
                    > Voltar
                    </CustomButton>

                    <CustomButton
                    v-if="!readonly && !isEdit"
                    @click="showCreate"
                    :disabled="!isValid"
                    > Criar campanha
                    </CustomButton>


                    <b-row>
                        <CustomButton
                        v-if="isEdit"
                        @click="showInclude"
                        bgColor="#28A745"
                        borderColor="#28A745"
                        variant="success"
                        > Incluir ativos
                        </CustomButton>

                        <div class="px-3"></div>

                        <CustomButton
                        v-if="isEdit"
                        @click="showEdit"
                        :disabled="!isValid"
                        > Salvar
                        </CustomButton>
                    </b-row>
                </b-row>
            </template>

            </DefaultPageComponent>

        </b-col>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import CustomButton from '@/components/CustomButton.vue';
import InputDatePicker from '@/components/InputDatePicker.vue';
export default {
    name: "PendingRulesView",
    components:{
        DefaultPageComponent,
        CustomButton,
        InputDatePicker,
    },
    data(){
        return {
            loading: false,
            loadingSubmit: false,

            readonly: false,
            isEdit: false,
            items: [],
            listActives: [],

            minDate: null,
            maxDate: null,

            dateStart: null,
            dateEnd: null,
            title: null,
            description: null,

            detail: null,

            filterBy: '',
            query: '',
            newType: '',
            rendaFixaColumns: [
                {
                    'product': 
                    {
                        label: 'Categoria',
                        class: 'text-left',
                    }
                },
                {
                    'subproduct': 
                    {
                        label: 'Sub categoria',
                        class: 'text-left',
                    }
                },
                {
                    'active': 
                    {
                        label: 'Ativo',
                        class: 'text-left',
                    }
                },
                {
                    'issuer': 
                    {
                        label: 'Emissor',
                        class: 'text-left',
                    }
                },
                {
                    'due_date': 
                    {
                        label: 'Data de vencimento',
                        class: 'text-right',
                        formatter: (value) => {
                            return this.$options.filters.formatDate(value);
                        },
                    }
                },
                {
                    'actions': 
                    {
                        label: '',
                        class: 'text-right pb-3',
                    }
                },
            ],
            columns: [
                {
                    'product': 
                    {
                        label: 'Categoria',
                        class: 'text-left',
                    }
                },
                {
                    'subproduct': 
                    {
                        label: 'Sub categoria',
                        class: 'text-left',
                    }
                },
                {
                    'n_document_fund': 
                    {
                        label: 'CNPJ',
                        class: 'text-left',
                        formatter: (value) => {
                            return this.$options.filters.formatCnpj(value);
                        },
                    }
                },
                {
                    'active': 
                    {
                        label: 'Ativo',
                        class: 'text-left',
                    }
                },
                {
                    'issuer': 
                    {
                        label: 'Emissor',
                        class: 'text-left',
                    }
                },
                {
                    'roa_funds': 
                    {
                        label: 'ROA',
                        class: 'text-left',
                    }
                },
                {
                    'actions': 
                    {
                        label: '',
                        class: 'text-right pb-3',
                    }
                },
            ],
        }
    },
    mounted(){
        if(!this.$route.params.type && !this.$route.params.listActives) this.$router.push({name:'campaigns'})

        this.newType = this.$route.params.type;
        this.listActives = this.$route.params.listActives;
        this.readonly = this.$route.params.readonly || false;
        this.isEdit = this.$route.params.edit || false;

        if(this.$route.params.detailId)
            this.detailId = this.$route.params.detailId

        if(this.$route.params.detail){
            this.detail = this.$route.params.detail
            this.dateStart = this.detail.start_date
            this.dateEnd = this.detail.end_date
            this.title = this.detail.title
            this.description = this.detail.description
        }

        if(this.$route.params.detailId && !this.detail)
            this.getDetails(this.$route.params.detailId);
            
    },
    computed:{
        isValid(){
            return (
                this.dateStart
                && this.dateEnd
                && this.title
                && this.description
                && this.listActives.length>0
            );
        },
    },
    methods: {
        async getDetails(id){
            this.detailId = id
            const response = await this.$axios.get('campaigns/'+id)
            this.detail = response.data.data

            this.dateStart = this.detail.start_date
            this.dateEnd = this.detail.end_date
            this.title = this.detail.title
            this.description = this.detail.description

            this.listActives = this.detail.items_investment_campaign.map(item=>{
                item.roa_funds = item.roa;
                item.product = item.category;
                item.subproduct = item.sub_category;
                item.n_document_fund = item.n_document;
                return item;
            })
        },
        back(){
            if(this.readonly || this.isEdit)
                this.$router.push({ name:'campaigns' })
            else
                this.$router.push({ name:'newCampaign', params: {type: this.newType, listActives: this.listActives} })
        },
        async getMinDate(date){
            if(!date) this.minDate=null;
            else this.minDate = new Date(date).toISOString().split('T')[0];
        },
        async getMaxDate(date){
            if(!date) this.maxDate=null;
            else this.maxDate = new Date(date).toISOString().split('T')[0];
        },
        showInclude(){
            this.detail.start_date = this.dateStart
            this.detail.end_date = this.dateEnd
            this.detail.title = this.title
            this.detail.description = this.description

            this.$router.push({ name:'newCampaign', params: {detailId: this.detailId, isEdit: this.isEdit, detail: this.detail, type: this.newType, listActives: this.listActives} })
        },
        async showEdit(){
            if(this.listActives.length==0){
                this.$swal({
                    title: 'Atenção',
                    text: 'Nenhum ativo incluído na campanha.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C7300C',
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }
            else if(!this.isValid){
                this.$swal({
                    title: 'Atenção',
                    text: 'Preencha todos os campos do formulário.',
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C7300C',
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
                return;
            }

            try{
                this.loading = true;

                var model = {
                    type: null,
                    title: this.title,
                    description: this.description,
                    start_date: this.dateStart,
                    end_date: this.dateEnd,
                    items_investment_campaign: this.listActives.map(e=>{e.roa = e.roa_funds; e.n_document = e.n_document_fund; return e})
                };

                if(this.newType == "Renda Fixa") model.type = 1;
                if(this.newType == "Fundos") model.type = 2;
                if(this.newType == "Previdência") model.type = 3;

                model.items_investment_campaign.forEach(e=>e.type=model.type);

                await this.$axios.put('campaigns/'+this.detailId, model);

                this.loading = false;

                this.$swal({
                    title: 'Sucesso',
                    html: `Campanha <span style="color:#28A745;"> ${this.title} </span> foi editada com sucesso!`,
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#28A745',
                    allowOutsideClick: false,
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                        denyButton: 'custom-confirm-button-class',
                    }
                }).then(async () => {
                    this.$router.push({ name: "campaigns" });
                });
            }
            catch(error){
                console.log(error);
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível editar a campanha.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C7300C',
                    customClass: {
                        confirmButton: 'custom-confirm-button-class',
                    }
                });
            }
            finally{
                this.loading = false;
            }
        },
        showCreate(){
            if(!this.isValid) {
                this.$swal({
                    title: 'Atenção',
                    text: `Preencha o formulário.`,
                    icon: 'warning',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C7300C',
                });
                return;
            }
            this.$swal({
                title: 'Criar nova campanha',
                html: `Deseja realmente criar a campanha <br/> <span style="color:#28A745;"> ${this.title} </span> ?`,
                icon: 'question',
                showDenyButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                denyButtonText: 'Criar',
                denyButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    denyButton: 'custom-confirm-button-class',
                }
            }).then(async (result) => {
                if (!result.isConfirmed){
                    this.create();
                }
            });
        },
        showRemoveActive(item){
            this.$swal({
                title: 'Excluir o ativo da campanha?',
                text: `Deseja realmente excluir o ativo da listagem?`,
                icon: 'question',
                showDenyButton: true,
                confirmButtonText: 'Cancelar',
                confirmButtonColor: '#C7300C',
                denyButtonText: 'Excluir',
                denyButtonColor: '#28A745',
                allowOutsideClick: false,
                customClass: {
                    confirmButton: 'custom-confirm-button-class',
                    denyButton: 'custom-confirm-button-class',
                }
            }).then(async (result) => {
                if (!result.isConfirmed){
                    this.removeActive(item);
                }
            });
        },
        removeActive(item){
            var idx = this.listActives.findIndex(e=>e==item);
            this.listActives.splice(idx, 1);
        },
        async create(){
            try{
                this.loading = true;

                var model = {
                    type: null,
                    title: this.title,
                    description: this.description,
                    start_date: this.dateStart,
                    end_date: this.dateEnd,
                    items_investment_campaign: this.listActives.map(e=>{e.roa = e.roa_funds; e.n_document = e.n_document_fund; return e})
                };

                if(this.newType == "Renda Fixa") model.type = 1;
                if(this.newType == "Fundos") model.type = 2;
                if(this.newType == "Previdência") model.type = 3;

                model.items_investment_campaign.forEach(e=>e.type=model.type);

                await this.$axios.post('campaigns', model);

                this.$swal({
                    title: 'Sucesso',
                    html: `Campanha <span style="color:#eb694a;"> ${this.title} </span> foi criada com sucesso!`,
                    icon: 'success',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C7300C',
                }).then(() => {
                    this.$router.push({name:'campaigns'})
                });
            }
            catch(error){
                console.log(error);
                this.$swal({
                    title: 'Erro',
                    text: `Não foi possível criar a campanha.`,
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C7300C',
                });
            }
            finally{
                this.loading = false;
            }
        },
    },
}
</script>

<style scoped>
 * /deep/ th{
    transform:translateY(-1px);
}
* /deep/ td{
    border:none !important;
    background-color: #2F2E2E !important;
}
/* * /deep/ table tbody tr td{
    padding: 5px 10px !important;
} */
* /deep/ .close{
    color: white !important;
}
* /deep/ .modal-header{
    border: none !important;
}
* /deep/ .modal-body{
    background-color: #2B2B2B !important;
}
* /deep/ textarea::placeholder{
    color:#E5E2E1 !important;
}
</style>